import React from 'react';
import { Link, graphql } from 'gatsby';

import SEO from "../../components/Seo"
import Layout from "../../components/Layout"

export default function Posts({ data }) {
  const { edges } = data.allMarkdownRemark;
  
  return (
    <Layout>
      <SEO title="Blog" />
      <div>
        {edges.map(({ node }) => {
          const { frontmatter } = node;
          return (
            <>
              <Link to={frontmatter.path}>
                <h1 key={frontmatter.path}>{frontmatter.title}</h1>
              </Link>
            </>
          )
        })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allMarkdownRemark {
      edges {
        node {
          html
          frontmatter {
            title
            date(formatString: "DD/MM")
            path
          }
        }
      }
    }
  }
`;
